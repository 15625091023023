import React, { useState, useEffect } from "react";
import "./paysheet.css";
import axios from "axios";
import Cookies from "universal-cookie";
import PdfImage from "./assets/pdf_1.jpeg";
import {
  Page,
  Image,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import Popup from "reactjs-popup";

const Paysheet = () => {
  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "#FFFFFF",
      fontSize: "10px",
      margin: "0",
      fontFamily: "Times-Roman",
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    title: {
      fontSize: "15px",
      textAlign: "center",
    },
    image: {
      width: "40%",
      alignSelf: "center",
    },
  });

  const MyDocument = () => (
    <Document>
      <Page size="A5" style={styles.page}>
        <View style={styles.section}>
          <Image src={PdfImage} style={styles.image} />
          <Text> </Text>
          <Text style={styles.title}>REHABILITACIÓN INTEGRAL INFANTIL</Text>
          <Text style={styles.title}>ESPECIALIZADA S.A.S</Text>
          <Text style={styles.title}>NIT 900247822-3</Text>
          <Text style={styles.title}>
            Nomina correspondiente al mes de {pdfdata.Mes} del año {pdfdata.Año}
          </Text>
          <Text> </Text>
          <Text>Nombre del trabajador: {pdfdata.Nombre}</Text>
          <Text>Identificación:{pdfdata.Documento}</Text>
          <Text> </Text>
          <Text>Sueldo basico: {pdfdata.SueldoBasico}</Text>
          <Text>Dias trabajados: {pdfdata.DiasTrabajados}</Text>
          <Text>Devengado: {pdfdata.SueldoDevengado}</Text>
          <Text>Incapacidad: {pdfdata.ValorIncapac}</Text>
          <Text>Vacaciones: {pdfdata.Vacaciones}</Text>
          <Text>Auxilio de transporte: {pdfdata.AuxTransp}</Text>
          <Text>Turnos extras: {pdfdata.HorasExtras}</Text>
          <Text>Otros devengados: {pdfdata.OtrosDevengados}</Text>
          <Text>Total devengados: {pdfdata.TotalDevengado}</Text>
          <Text> </Text>
          <Text>Aportes EPS: {pdfdata.ValorEPS}</Text>
          <Text>Aportes pension: {pdfdata.ValorPension}</Text>
          <Text>Retencion en la fuente: {pdfdata.ValorReteFte}</Text>
          <Text>Fondo de solidaridad: {pdfdata.FondoSolidario}</Text>
          <Text>Prestamos: {pdfdata.ValorPrestamos}</Text>
          <Text>Otros descuentos: {pdfdata.OtrosDeducidos}</Text>
          <Text>Total descuentos: {pdfdata.TotalDescuentos}</Text>
          <Text> </Text>
          <Text>Neto a pagar: {pdfdata.NetoAPagar}</Text>
        </View>
      </Page>
    </Document>
  );

  const cookies = new Cookies();
  const [data, setData] = useState([]);
  const [month, setMonth] = useState("Enero");
  const [year, setYear] = useState("2023");
  const [pdfdata, setPDFdata] = useState([]);

  const GetPaysheet = () => {
    var FiltroMes = data.filter((element) => {
      if (element.Año === year) {
        if (element.Mes === month) {
          return element;
        }
      }
    });
    setPDFdata(FiltroMes[0]);
  };

  const fetchallData = async () => {
    try {
      const res = await axios.get(
        "https://portal-colaboradores.riie.com.co/server/paysheet_crud_2.php",
        {
          params: {
            Request: "paysheet_registers",
            PaysheetID: cookies.get("idnum"),
          },
        }
      );
      setData(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchallData();
  }, []);

  if (cookies.get("perms") === "admin") {
    return (
      <>
        <div className="control_panel">
          <div className="filter">
            <select onChange={(e) => setMonth(e.target.value)}>
              <option hidden>Seleccione el mes</option>
              {data.map((element) => (
                <option>{element.Mes}</option>
              ))}
            </select>
            <select onChange={(e) => setYear(e.target.value)}>
              <option hidden>Seleccione el año</option>
              <option>2023</option>
              <option>2024</option>
              <option>2025</option>
            </select>
          </div>
          <div className="buttons">
            <button onClick={GetPaysheet}>Generar pdf</button>
            <Popup
              modal
              trigger={<button>Visualizar y descargar PDF</button>}
              className="Popup"
              contentStyle={{ width: "90%", height: "80%" }}
            >
              <PDFViewer className="PDFViewer">
                <MyDocument />
              </PDFViewer>
            </Popup>
          </div>
        </div>
        <div className="table_container">
          <table className="tab_content">
            <thead>
              <tr>
                <th>Año</th>
                <th>Mes</th>
                <th>Nombre</th>
                <th>Documento</th>
                <th>Sueldo basico</th>
                <th>Dias trabajados</th>
                <th>Sueldo devengado</th>
                <th>Valor incapacidades</th>
                <th>Horas extra</th>
                <th>Auxilio de transportes</th>
                <th>Vacaciones</th>
                <th>Licencias remuneradas</th>
                <th>Otros devengados</th>
                <th>Total devengado</th>
                <th>Valor EPS</th>
                <th>Valor pension</th>
                <th>Valor retefuente</th>
                <th>Fondo solidario</th>
                <th>Valor prestamos</th>
                <th>Otros deducidos</th>
                <th>Total descuentos</th>
                <th>Neto a pagar</th>
                <th>Saldo prima</th>
                <th>Intereses Cesantias</th>
              </tr>
            </thead>

            <tbody>
              {data.map((element, key) => (
                <tr key={key}>
                  <td>{element.Año}</td>
                  <td>{element.Mes}</td>
                  <td>{element.Nombre}</td>
                  <td>{element.Documento}</td>
                  <td>{element.SueldoBasico}</td>
                  <td>{element.DiasTrabajados}</td>
                  <td>{element.SueldoDevengado}</td>
                  <td>{element.ValorIncapac}</td>
                  <td>{element.HorasExtras}</td>
                  <td>{element.AuxTransp}</td>
                  <td>{element.Vacaciones}</td>
                  <td>{element.LicenciaRemuner}</td>
                  <td>{element.OtrosDevengados}</td>
                  <td>{element.TotalDevengado}</td>
                  <td>{element.ValorEPS}</td>
                  <td>{element.ValorPension}</td>
                  <td>{element.ValorReteFte}</td>
                  <td>{element.FondoSolidario}</td>
                  <td>{element.ValorPrestamos}</td>
                  <td>{element.OtrosDeducidos}</td>
                  <td>{element.TotalDescuentos}</td>
                  <td>{element.NetoAPagar}</td>
                  <td>{element.SaldoPrima}</td>
                  <td>{element.IntCesantias}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  } else {
    return (
      <>
        <select
          onChange={(e) => {
            setMonth(e.target.value);
          }}
        >
          <option hidden>Seleccione el mes</option>

          {data.map((element) => (
            <option>{element.Mes}</option>
          ))}
        </select>
        <select onChange={(e) => setYear(e.target.value)}>
          <option hidden>Seleccione el año</option>
          <option>2023</option>
          <option>2024</option>
          <option>2025</option>
        </select>
        <button onClick={GetPaysheet}>Generar pdf</button>
        <Popup
          modal
          trigger={<button>ver PDF</button>}
          className="Popup"
          contentStyle={{ width: "90%", height: "80%" }}
        >
          <PDFViewer className="PDFViewer">
            <MyDocument />
          </PDFViewer>
        </Popup>
        <table>
          <thead>
            <tr>
              <th>Año</th>
              <th>Mes</th>
              <th>Nombre</th>
              <th>Documento</th>
              <th>Sueldo basico</th>
              <th>Dias trabajados</th>
              <th>Sueldo devengado</th>
              <th>Valor incapacidades</th>
              <th>Horas extra</th>
              <th>Auxilio de transportes</th>
              <th>Vacaciones</th>
              <th>Licencias remuneradas</th>
              <th>Otros devengados</th>
              <th>Total devengado</th>
              <th>Valor EPS</th>
              <th>Valor pension</th>
              <th>Valor retefuente</th>
              <th>Fondo solidario</th>
              <th>Valor prestamos</th>
              <th>Otros deducidos</th>
              <th>Total descuentos</th>
              <th>Neto a pagar</th>
            </tr>
          </thead>

          <tbody>
            {data.map((element, key) => (
              <tr key={key}>
                <td>{element.Año}</td>
                <td>{element.Mes}</td>
                <td>{element.Nombre}</td>
                <td>{element.Documento}</td>
                <td>{element.SueldoBasico}</td>
                <td>{element.DiasTrabajados}</td>
                <td>{element.SueldoDevengado}</td>
                <td>{element.ValorIncapac}</td>
                <td>{element.HorasExtras}</td>
                <td>{element.AuxTransp}</td>
                <td>{element.Vacaciones}</td>
                <td>{element.LicenciaRemuner}</td>
                <td>{element.OtrosDevengados}</td>
                <td>{element.TotalDevengado}</td>
                <td>{element.ValorEPS}</td>
                <td>{element.ValorPension}</td>
                <td>{element.ValorReteFte}</td>
                <td>{element.FondoSolidario}</td>
                <td>{element.ValorPrestamos}</td>
                <td>{element.OtrosDeducidos}</td>
                <td>{element.TotalDescuentos}</td>
                <td>{element.NetoAPagar}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  }
};

export default Paysheet;
